import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { numberWithCommas } from '../../../../../../Utils/NumberToComma';
// import { get } from '../../../../../../Model/Network/Config/Axios';

function GrnConditions(props) {
    // const { id } = props
    const [state, ] = useState({
        loading: false,
        items: []
    });

    const { items } = state;

    useEffect(() => {
        // BE Not deployed Yet
        // get(`vendor/procurement/poCondition/${id}/getPoItemConditionsGrns`, (e, r) => {
        //     if (r) {
        //         setState({
        //             ...state,
        //             loading: false,
        //             items:r.poConditionGrnList
        //         })
        //     } else {
        //         setState({ loading: false })
        //     }
        // })

    }, [])



    return (
        <>
            <div style={{ padding: '20px 40px', borderRadius: '6px', marginTop: '20px' }}>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Conditions GRN</div>
                <table style={{ border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px', fontSize: '12px', width: '100%' }}>
                    <tr style={{ background: 'var(--lite-color)', textAlign: 'left' }}>
                        <th style={{ padding: '15px' }}>Condition & Vendor</th>
                        <th style={{ padding: '15px' }}>Date</th>
                        <th style={{ padding: '15px' }}>Grn Qty</th>
                        <th style={{ padding: '15px' }}>Invoice Qty</th>
                        <th style={{ padding: '15px' }}>Status</th>
                        <th style={{ padding: '15px' }}>Amount</th>
                        {/* <th>Action</th> */}
                    </tr>
                    {items.length >0 ?
                        items.map(row => {
                            return (
                                <tr style={{ background: '#fff' }}>
                                    <td style={{ padding: '15px',textAlign:'left' }}>
                                        <div><b>{row.title}</b></div>
                                         <div>{row.vendorName||''} </div>
                                         <div style={{fontSize:10}}>{row.vendorCode||''} </div>
                                    </td>
                                    <td style={{ padding: '15px' }}>{moment(row.date).format('DD-MM-YYYY')}</td>
                                    <td style={{ padding: '15px' }}>{row.qty ? row.qty : '-'}</td>
                                    <td style={{ padding: '15px' }}>{row.invoiceQty ? row.invoiceQty : '0'}</td>
                                    <td style={{ padding: '15px' }}>
                                        <div style={{
                                            background:'var(--green-color)',
                                            padding: 3,
                                            borderRadius: 6,
                                            color: '#fff'
                                        }}>
                                            {'Completed'}
                                        </div>
                                    </td>
                                    <td style={{ padding: '15px' }}>{row.currency} {row.price ? numberWithCommas(row.price) : '-'}</td>
                                    {/* Details api not available */}
                                    {/* <td onClick={() => setGRNModal(row)} style={{ fontWeight: 'bold', padding: '15px', cursor: 'pointer', color: 'var(--primary-color)' }}>
                                        <svg style={{ marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                        </svg>
                                        OPEN
                                    </td> */}
                                </tr>
                            )
                        }) :
                        <tr style={{ background: '#fff', textAlign: 'center' }}>
                            <td style={{ padding: '15px' }} colSpan={12}>
                                No GRNs
                            </td>
                        </tr>

                    }
                </table>
            </div>
            {/* {
                GRNModal ?
                    <GrnView
                        selectedGrnId={GRNModal.id}
                        viewGrn={GRNModal}
                        onClose={() => setGRNModal(undefined)}
                        {...props}
                    />
                    : <></>
            } */}
        </>
    )
}

export default GrnConditions;