import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { PDFExport } from "@progress/kendo-react-pdf";
import Moment from 'moment';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';
import Terms from '../Common/Terms';

function NinjaCart(props) {
  console.log(props)
  const {data, defaultBank} = props
  const pdf = useRef(null);

  let totalBaseAmount = 0;
  let totalTax = 0;
  data.procured.forEach(val => {
    totalBaseAmount += val.base;
    totalTax += val.tax;
  })
  return (
    <div style={{ width: "800px", margin: "auto" }}>
        <div style={{ textAlign: "right" }} >
            <p
                style={{
                    fontWeight: 600,
                    color: "var(--primary-color)",
                    cursor: "pointer",
                }}
                onClick={() => { pdf.current.save() }}
            >
                Download Now
            </p>
        </div>
        <PDFExport 
            title="Order Document"
            padding="50"
            fileName={`Order-${data.orderId}.pdf`}
            ref={(r) => (pdf.current = r)}
        >
      <div style={{border:'1px solid black'}} >
      <table style={{ width: "100%", marginBottom: "20px"}}>
        <tbody>
          <tr>
            <td colSpan="4" style={{ padding: 0 }}>
              <img
                style={{ height: "40px", margin: "10px" }}
                src="https://static.eka.io/v2/receipts/2024/09/70451e31-5467-4178-abea-33c889517964.png"
                alt="Logo"
              />
              <h2 style={{ float: "right", margin: "15px", display: "inline" }}>Purchase Order</h2>
            </td>
            <td colSpan="2" style={{ padding: 0 }}>
              <table style={{ margin: 0 }}>
                <tbody>
                  <tr>
                    <td><strong>Doc No</strong></td>
                    <td style={{ textAlign: "right" }}>{data.orderId}</td>
                  </tr>
                  <tr>
                    <td><strong>Doc Date</strong></td>
                    <td style={{ textAlign: "right" }}>{Moment(data.details.createdAt).format('DD-MM-YYYY')}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="3" style={{ padding: 0, verticalAlign: "top", width: "50%", border:'1px solid black', textAlign: "left" }}>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Bill To:</strong>
              <div>
                <span><strong>Registered Name :</strong> {data.billingTo.registered_name}</span><br />
                <span><strong>Billing Address :</strong> {data.billingTo.address}</span><br />
                <span><strong>GSTIN :</strong> {data.billingTo.gstin}</span>
              </div>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Ship-To:</strong>
              <div>
                <span><strong>Office Registered Name :</strong>  {data.procured[0].officeRegisteredName}</span><br />
                <span><strong>Shipping Address :</strong>  {data.procured[0].officeAddress}</span><br />
                <span><strong>GSTIN :</strong>  {data.procured[0].officeGstin}</span><br />
              </div>
            </td>
            <td colSpan="3" style={{ padding: 0, verticalAlign: "top", border:'1px solid black', textAlign: "left" }}>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Information</strong>
              <div>
                <span>PAN: {data.pan}</span><br />
                <span>GSTIN: {data.details.officeGstin}</span><br />
                <span>Email: {data.adminEmail}</span>
              </div>
              <strong style={{ backgroundColor: "#c9c9c9", display: "block", borderBottom: "1px solid black", borderTop: "1px solid black" }}>Vendor Details:</strong>
              <div>
                <span>Vendor Id :{data.vendor.id}</span><br />
                <span>{data.vendor.name}</span><br />
                <span>{data.vendor.address ? data.vendor.address.address : null}</span><br />
              </div>
              <table>
                <tbody>
                  <tr>
                    <td style={{padding:0}} ><strong>GSTIN:</strong></td>
                    <td style={{padding:0}} >{data.vendor.gstin}</td>
                  </tr>
                  <tr>
                    <td style={{padding:0}} ><strong>PAN:</strong></td>
                    <td style={{padding:0}} >{data.vendor.pan}</td>
                  </tr>
                  <tr>
                    <td style={{padding:0}} ><strong>Contact Number:</strong></td>
                    <td style={{padding:0}} >{data.vendor.mobile}</td>
                  </tr>
                  <tr>
                    <td style={{padding:0}} ><strong>E-Mail:</strong></td>
                    <td style={{padding:0}} >{data.vendor.email}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{ border: "none", padding: 0 }}>
        <thead>
          <tr style={{ backgroundColor: "#c9c9c9" }}>
            <th>Item Code</th>
            <th>Item Name and Description</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>UOM</th>
            <th>Rate (INR)</th>
            <th>Tax Percentage (%)</th>
            <th style={{ textAlign: "right" }}>Amount ({data.currency})</th>
          </tr>
        </thead>
        <tbody>
        {data.procured.map((item, index) => (
          <tr key={index}>
            <td>{item.productCode}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{item.qtty}</td>
            <td>{item.unit}</td>
            <td>{numberWithCommas(item.price.toFixed(2))} </td>
            <td>{numberWithCommas(item.taxSlab.toFixed(1))+'%'}</td>
            <td style={{ textAlign: "right" }}>{numberWithCommas(item.amount.toFixed(2))}</td>
          </tr>
        ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" style={{ border: "none", padding: 0 }}>
              {/* <strong style={{ backgroundColor: "#c9c9c9", display: "block", border: "1px solid black", padding: "5px" }}>Terms and Conditions:</strong>
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td><strong>Payment Terms:</strong></td>
                    <td>100% after 30 days from Invoice date</td>
                  </tr>
                  <tr>
                    <td><strong>Delivery:</strong></td>
                    <td>{Moment(data.procured[0].deliveryDate).format('MM-DD-YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Warranty:</strong></td>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <td><strong>Remarks:</strong></td>
                    <td>Packing materails for BLR B2C.</td>
                  </tr>
                  <tr>
                    <td><strong>Specifications:</strong></td>
                    <td>{data.specifications}</td>
                  </tr>
                </tbody>
              </table> */}
            </td>
            <td colSpan="3" style={{ padding: 0, verticalAlign: "top" }}>
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td><strong>Sub Total:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.currency} {totalBaseAmount.toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td><strong>Tax Amount:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.currency} {totalTax.toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td><strong>Total:</strong></td>
                    <td style={{ textAlign: "right" }}>{data.currency} {(totalBaseAmount+totalTax).toFixed(1)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tfoot>
      </table>

      <Terms title = "Terms and Condtions" conditions={[...data.conditions]} />
      <Terms title = "Payment Terms" conditions={[...data.payments]} />

      <table>
        <thead>
          <tr style={{ backgroundColor: "#c9c9c9" }}>
            <th colSpan="4" style={{ textAlign: "center" }}>Vendor Bank Account Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{textAlign:'left'}} >Beneficiary Name :</td>
            <td style={{textAlign:'left'}} >{data.vendor.name}</td>
            <td style={{textAlign:'left'}} >Account No :</td>
            <td style={{textAlign:'left'}} >{defaultBank.account}</td>
          </tr>
          <tr>
            <td style={{textAlign:'left'}} >Bank Name :</td>
            <td style={{textAlign:'left'}} >{defaultBank.bankName}</td>
            <td style={{textAlign:'left'}} >IFS Code :</td>
            <td style={{textAlign:'left'}} >{defaultBank.ifsc}</td>
          </tr>
        </tbody>
      </table>
      </div>
      </PDFExport>
    </div>
  );
}

export default connect((state) => {
  return {
    config: state.auth.config,
  };
}, null)(NinjaCart);
