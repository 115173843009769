import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {post} from "../../../../../Model/Network/Config/Axios";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {Title as Header} from "../../../../Header/TopHeader";
import Items from './AllItems'
import Terms from './Terms'
import Payment from './Payment'
import Grn from './Grn';
import OfficeModal from '../../../../Common/fModal/OfficeModal';
import Invoices from './Invoices';
import VendorTerms from './VendorTerms';
import View from './View';
import Advance from './Advance';
import { Modal } from '../../../../Common/fModal/Modal';
import { DatePickerCustom, FunctionalInput } from '../../../../Common/fInputs';
import { EmployeeSearchable } from '../../../../Common/Class/Select/EmployeeSearchable';
import AllGrn from './GRN/AllGrn'
import { VendorAddressSearch } from '../../../../Common/fVendor/VendorAddressSearch';
import { EditableCustomForm } from '../../../../Common/EditableCustomForm';
import ProvisionDetails from './ProvisionCondition/index';
import Asn from './asn';
import { ProductSearch } from '../fRfqDetails/ProductSearch';
import useDateFormat from '../../../fCommon/useDateFormat';
import { SearchOffice2 } from '../../../../Common/fSubmitInvoice/common/SearchOffice';
import moment from 'moment';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';
import GrnConditions from './GRN/GrnConditions';


const Details = (props) => {



	const [short,setShort] = useState(false);
	const [name,setName] = useState(false);
	const [addressType, setAddressType] = useState(false);
	const [employee,setEmployee] = useState(false);
	const [vendorAddress,setVendorAddress] = useState(false);
	const [addLineItem,setAddLineItem] = useState(undefined);
	const [product,setProduct] = useState(undefined);
    const [shipping,setShipping] = useState(undefined);
	let tenant = useSelector(state=>state.auth.config.tenant);
	let {optionalFields}= useSelector(state=>state.auth.config.plugins);

	let REMARKS = useRef(),QTTY=useRef(),BASE=useRef(),DELIVERY_DATE=useRef(),NOTES=useRef();
	let dateFormate = useDateFormat('MM-DD-YYYY')
	const {grn} = useSelector(config=>{
		return{grn:config.auth.config.plugins.grn}
	})

	let scrollInto = (terms) => {
		let section = document.getElementById(terms);
		if (section) {
			section.classList.add('blinkIt');
			setTimeout(() => {
				section = document.getElementById(terms);
				if (section) {
					section.classList.remove('blinkIt');
				}
			}, 2000);
			section.scrollIntoView({behavior: 'smooth', block: 'start'});
		}
	}

	let button = () => {
		let {data, id, init} = props;
		let {status} = data;
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				position: 'absolute',
				right: 12,
				top: 25,
				justifyContent: 'center'
			}}>

				{
					status === "DRAFT" ? <ReadOnly>
					<div style={{
						fontSize: 11,
						marginLeft: 20,
						width: 200,
					}} className="btn btn-portlet" onClick={() => {
						post(`vendor/po/requests/${id}/draft`, {}, (e, r) => {
							if (r) {
								init()
							} else {
								handleError(e)
							}
						})
					}}>
						Move Next
					</div>
					</ReadOnly> : undefined
				}

				{
					status === "ADMINTOUCH" ? <ReadOnly><div style={{
						fontSize: 11,
						marginLeft: 20,
						width: 200,
					}} className="btn btn-portlet" onClick={() => {
						post(`vendor/po/requests/${id}/touch`, {}, (e, r) => {
							if (r) {
								init()
							} else {
								handleError(e)
							}
						})
					}}>
						Move Next
					</div></ReadOnly> : undefined
				}
				{
					status === "HOLD" && false ? <ReadOnly><div style={{
						fontSize: 11,
						marginLeft: 20,
						width: 200,
					}} className="btn btn-portlet" onClick={() => {
						post(`vendor/po/requests/${id}/moveGrn`, {}, (e, r) => {
							if (r) {
								init()
							} else {
								handleError(e)
							}
						})
					}}>
						DO GRN
					</div></ReadOnly> : undefined
				}

				{
					status === "SCHEDULE" ?
						<ReadOnly>
						<div style={{
							fontSize: 11
						}} className="btn btn-portlet" onClick={() => {
							post(`vendor/po/schedule/${id}/move`, {}, (e, r) => {
								if (r) {
									init()
								} else {
									handleError(e)
								}
							})
						}}>
							Post Po
						</div>
						</ReadOnly> : undefined
				}

				{tenant && tenant !== 'DTDC2' ?
					status === 'APPROVAL' || status === 'INVOICE'  ?
						<ReadOnly>
						<div style={{
							fontSize: 11,
							marginLeft: 20,
							width: 200,
						}} className="btn btn-portlet" onClick={() => {
							post(`vendor/po/requests/${id}/complete`, {}, (e, r) => {
								if (r) {
									init()
								} else {
									handleError(e)
								}
							})
						}}>
							Complete
						</div>
						</ReadOnly> : undefined
				: null}

			</div>
		)
	}


	let {data, id,init} = props;
	let { vendor, status, invoices} = data;
	const savePoItem = () =>{
		let qtty = QTTY.current.value;
		let base = BASE.current.value;
		let notes = NOTES.current.value;
		let date = DELIVERY_DATE.current.value;

		if(!product) {
			showToast({
				type:'error',
				message:'Please select the product first'
			})
			return;
		}

		if(!shipping) {
			showToast({
				type:'error',
				message:'Please select the Delivery address'
			})
			return;
		}

		if(!date) {
			showToast({
				type:'error',
				message:'Please select the Delivery date'
			})
			return;
		}

		if(!qtty) {
			showToast({
				type:'error',
				message:"Enter the quantity"
			})
			return;
		}
		if(!base) {
			showToast({
				type:'error',
				message:"Please enter the base amount"
			})
			return
		}
		if(!notes && !optionalFields) {
			showToast({
				type:'error',
				message:"Please enter the notes"
			})
			return;
		}
	
		let data = {
		  qtty: Number(parseFloat(qtty).toFixed(3)),
		  product:{
			value:product.value
		  },
		  base: +base,
		  notes,
		  shipTo:shipping ? {value:shipping.value }: null
		}
		if(date) {
			data.date = date.valueOf()
		}
		
		post(`vendor/po/requests/${id}/addPoItem`,{item:data},(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:"Item added successfully"
				});
				props.init();
				setAddLineItem(false);
				setProduct(undefined)
				setShipping(undefined)
			} else {
				showToast({
					type:'error',
					message:e.data.message
				})
			}
		})
	}

	return (
		<div>
			<div className="input-container" style={{padding: 0, marginTop: 30, marginBottom: 0}}>
				<div style={{
					display: 'flex',
					padding: 25,
					position: 'relative',
				}}>
					{
						data.logo ?
							<div style={{
								flex: 0.2,
								marginBottom: 15
							}}>
								<img src={data.logo} alt="" style={{
									height: 40,
									objectFit: 'contain'
								}}
								/>
							</div> : null
					}
					<div>
						<p style={{
							fontSize: 21,
							color: "var(--text-color)",
							fontWeight: 700
						}}>
							{data.orderId}
						</p>
						<p style={{
							fontSize: 11,
							color: 'var(--light-color)'
						}}>
							Details of selected po appears below.
						</p>
					</div>
					{
						button()
					}
				</div>
				<div style={{borderTop:'1px solid var(--border-color)',padding:20,paddingTop:10,paddingBottom:10}}>
					<p style={{fontWeight:600,fontSize:16}}>{data.title?data.title:'-'}</p>
					<div style={{
						display:'flex',
						alignItems:'center'
					}}>
						<p className='capsule'>CATEGORY : {data.category.name}</p>
						{
							data.poType?
							<p className='capsule'>PO Type : {data.poType.title || "-"}</p>
							:null
						}
						{
							data.endDate ?
							<p className='capsule'>End Date : {moment(data.endDate).format('DD-MM-YYYY')}</p>
							: null
						}
					</div>
				</div>
				<div style={{
					borderTop: '1px solid var(--border-color)',
					display: 'flex',
					flexDirection: 'row'
				}}>
					<div style={{
						width: 180,
						borderRight: '1px solid var(--border-color)',
						padding: 20
					}}>
						<p style={{
							fontSize: 9,
							color: "var(--light-color)",
							fontWeight: 600,
							letterSpacing: 1,
							marginBottom: 12,
							textTransform: 'uppercase'
						}}>
							Go to
						</p>
						<p onClick={() => {
							scrollInto("items")
						}} style={{
							fontSize: 12,
							color: "var(--primary-color)",
							marginTop: 5,
							fontWeight: 600,
							cursor: 'pointer'
						}}>
							Items
						</p>
						<p onClick={() => {
							scrollInto("attachments")
						}} style={{
							fontSize: 12,
							color: "var(--primary-color)",
							marginTop: 5,
							fontWeight: 600,
							cursor: 'pointer'
						}}>
							Attachments
						</p>
						<p onClick={() => {
							scrollInto("comments")
						}} style={{
							fontSize: 12,
							color: "var(--primary-color)",
							marginTop: 5,
							fontWeight: 600,
							cursor: 'pointer'
						}}>
							Comments
						</p>
						<p onClick={() => {
							scrollInto("timeline")
						}} style={{
							fontSize: 12,
							color: "var(--primary-color)",
							marginTop: 5,
							fontWeight: 600,
							cursor: 'pointer'
						}}>
							Timeline
						</p>
						<p onClick={() => {
							scrollInto("terms")
						}} style={{
							fontSize: 12,
							color: "var(--primary-color)",
							marginTop: 5,
							fontWeight: 600,
							cursor: 'pointer'
						}}>
							Terms & Condition
						</p>
					</div>
					<div className="flex-1" style={{
						padding: 18,
						display: 'flex'
					}}>
						<div style={{
							padding: 12,
							flex:1,
							border: '1px solid var(--border-color)'
						}}>
							<p style={{
								fontSize: 9,
								color: 'var(--light-color)',
							}}>
								Supplier Details {(invoices.length===0 || status==='DRAFT') ? <ReadOnly>
									<span style={{
									fontWeight: 700,
									paddingLeft: 10,
									textTransform: 'uppercase',
									cursor: 'pointer',
									color: "var(--light-color)"
								}} onClick={() => {
									setVendorAddress(vendor.id);
								}}>Edit</span>
								</ReadOnly> : null}
							</p>
							<p style={{
								marginTop: 10,
								color: "var(--text-color)",
								fontSize: 15,
								fontWeight: 600
							}}>
								{
									vendor.name
								}
							</p>
							<p style={{
								fontSize: 11,
								color: "var(--light-color)"
							}}>
								{vendor.email}
							</p>
							<p style={{
								fontSize: 11,
								color: "var(--light-color)"
							}}>
								{vendor.mobile}
							</p>
							<p style={{
								fontSize: 11,
								color: "var(--light-color)"
							}}>
								Gstin: {vendor.gstin ? vendor.gstin : '-'}
							</p>
						</div>

						<div style={{
							padding: 12,
							marginLeft:12,
							flex:1,
							border: '1px solid var(--border-color)'
						}}>
							<p style={{
								fontSize: 9,
								color: 'var(--light-color)',
							}}>
								Billing Details:- {(invoices.length===0 || status==='DRAFT')  ? <ReadOnly>
									<span style={{
									fontWeight: 700,
									paddingLeft: 10,
									textTransform: 'uppercase',
									cursor: 'pointer',
									color: "var(--light-color)"
								}} onClick={() => {
									setAddressType('billTo');
								}}>Edit</span>
								</ReadOnly> : null}
							</p>
							<p style={{
								color: "var(--text-color)",
								fontSize: 15,
								marginTop: 12,
								fontWeight: 600
							}}>
								{
									data.billingTo.name
								}
							</p>
							<p style={{
								color: "var(--light-color)",
								fontSize: 11,
							}}>
								{
									data.billingTo.address
								}
							</p>
							<p style={{
								fontSize: 11,
								color: "var(--light-color)"
							}}>
								{
									data.billingTo.city
								}
							</p>
							<p style={{
								fontSize: 11,
								color: "var(--light-color)"
							}}>
								{
									data.billingTo.country
								}
							</p>
							<p style={{
								fontSize: 11,
								color: "var(--light-color)"
							}}>
								{
									data.billingTo.gstin?data.billingTo.gstin:"-"
								}
							</p>
						</div>
						<div style={{
							padding: 12,
							marginLeft:12,
							flex:1,
							border: '1px solid var(--border-color)'
						}}>
							<p style={{
								fontSize: 9,
								color: 'var(--light-color)',
							}}>
								Invoice Owner Details
							</p>
							<p style={{
								color: "var(--text-color)",
								fontSize: 15,
								fontWeight: 600
							}}>
								<Link to={`/app/organisation/employee/${data.details.raisedBy.ownerId}`} target="_blank">
									<span style={{fontSize: '12px'}}>
										{data.details.raisedBy.ownerName}
									</span>
								</Link> {status !== 'COMPLETED' ?
								<ReadOnly><span style={{
								fontWeight: 700,
								paddingLeft: 10,
								textTransform: 'uppercase',
								cursor: 'pointer',
								color: "var(--light-color)",
								fontSize:9
							}} onClick={() => {
								setName("admin")
							}}>Edit</span></ReadOnly> : null }
							</p>
							<p style={{
								fontSize: 9,
								color: 'var(--light-color)',
								marginTop: '15px'
							}}>
								Grn Owner  {status !== 'COMPLETED' ?
								<ReadOnly>
									<span style={{
									fontWeight: 700,
									paddingLeft: 10,
									textTransform: 'uppercase',
									cursor: 'pointer',
									color: "var(--light-color)"
								}} onClick={() => {
									setName("grn")
								}}>Edit</span>
								</ReadOnly> : null }
							</p>
							<p style={{
								color: "var(--text-color)",
								fontSize: 15,
								fontWeight: 600
							}}>
								{data.grnOwner ?
								<Link to={`/app/organisation/employee/${data.grnOwner.ownerId}`} target="_blank">
									<span style={{fontSize: '12px'}}>
										{data.grnOwner.ownerName}
									</span>
								</Link>
								: 'Not-defined'
								}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div style={{
				padding: '0px 25px'
			}}>
				<Header container={true} id="items" title="Order Items" des="All order items are visible here">
					<div style={{
						position:'absolute',
						right:0
					}}>
						{status === 'DRAFT' ? (
							<ReadOnly>
								<div style={{
									fontSize: 13,
									cursor: 'pointer',
									fontWeight: 700,
									width: 125,
									color: "var(--primary-color)",
									textTransform: 'uppercase'
								}} onClick={() => {
									setAddLineItem(true)
								}}>
									+ Add Product
								</div>
							</ReadOnly>
						) : ((tenant && tenant === 'DTDC2') && status === 'PENDING') && (
							<ReadOnly>
							<div style={{
								fontSize: 13,
								cursor: 'pointer',
								fontWeight: 700,
								width: 125,
								color: "var(--primary-color)",
								textTransform: 'uppercase'
							}} onClick={() => {
								setAddLineItem(true)
							}}>
								+ Add Product
							</div>
							</ReadOnly>
						)}
					 { status === 'GRN'	&& <ReadOnly>
						<button style={{
								border:'var(--primary-color)',
								background:'var(--primary-color)',
								padding:'8px 20px',
								color:"var(--white-color)",
								borderRadius:4,
								cursor:'pointer'
							}} onClick={() => {
									setShort(true);
								}}>
								Short Closure
						</button>
					 </ReadOnly>
					}
					</div>
				</Header>
			</div>
			<div className="input-container" style={{padding: 25, marginBottom: 0}}>
				{
					<Items status={status} {...props} currency={props.data.currency}/>
				}
			</div>
			{
				grn && (status === "GRN" || status === "APPROVAL" || status === "INVOICE" || status === "COMPLETED" )?
							<Grn {...props} />
					: null
			}
			<AllGrn data={data} {...props} />
			<GrnConditions data={data} {...props} id={id} />

			<div style={{marginBottom: 0}}>
				<Invoices status={status} data={data}/>
			</div>

			{
				props.data.forms && props.data.forms.length ?
				<>
				<Header container={true} id="form" title="Form" />
				<div className='input-container' style={{marginTop:20}}>
					<EditableCustomForm type="po" id={props.data.category.id} onChange={(key,value)=>{
						let data = {
							key,
							value
						}
						if(status==='GRN' || status==='PENDING') {
							data.force = true
						}
						post(`vendor/po/requests/${id}/updateDetails`,data,(e,r)=>{
							if(r) {
								showToast({
									type:'success',
									message:"Form updated successfully"
								});
								props.init();
							} else {
								showToast({
									type:'error',
									message:e.data.message
								})
							}
						})
					}} data={props.data.forms}  />
				</div>
				</>
				:null
			}


			<div>

				<Asn  {...props}/>
				</div>
				<Advance {...props} init={()=>{
					init()
				}} />
				<View data={data}/>
				<VendorTerms {...props} />
				<Terms {...props}/>
				<Payment {...props}/>
				<ProvisionDetails {...props} />
				<div style={{marginLeft:20}}>
				<Header container={true} style={{marginLeft:20}} id="terms" title="Logs" />
				</div>
				<div className='input-container'>
				  {
                       data.logs&& data.logs.length>0?
					   data.logs.map((item, index)=>{
                                return(
									<div  key={`comments-${index}`}>
										<span style={{color: '#666', marginRight: '5px'}}>{moment(item.date).format(" hh:mm a DD MMM YY")}</span>
										: {item.log}
									   </div>	
								)
                            })
                            : <p>No Logs</p>
                  }
				</div>
			
			{
				addressType ?
					<OfficeModal
						p2p={true}
						close={() => {
							setAddressType(false);
						}}
						onSelect={(i) => {
							if (addressType === 'shipTo') {
								let data = {
									key:'shipTo',
									value:`${i.id}`
								}
								if(status==='GRN') {
									data.force = true
								}
								post(`vendor/po/requests/${id}/updateDetails`, data, (e, r) => {
									if (r) {
										props.init();
										setAddressType(false);

										showToast({
											type: 'success',
											message: "Address update successfuly"
										})
									} else {
										handleError(e);
									}
								})
							} else {
								let data = {
									key:'billTo',
									value:`${i.id}`
								}
								if(status==='GRN') {
									data.force = true
								}
								post(`vendor/po/requests/${id}/updateDetails`, data, (e, r) => {
									if (r) {
										props.init();
										setAddressType(false);

										showToast({
											type: 'success',
											message: "Address update successfuly"
										})
									} else {
										handleError(e);
									}
								})
							}
						}}
					/>
					: null
			}
			{vendorAddress ?
			<VendorAddressSearch
				close={()=>{
					setVendorAddress(false)
				}}
				office={data.details.addressId}
				id={vendorAddress}
				onSelect={(i)=>{
					let data = {
						key:'vendorAddress',
						value:i
					}
					if(status==='GRN') {
						data.force = true;
					}
					post(`vendor/po/requests/${id}/updateDetails`,data,(e,r)=>{
						if(r) {
							showToast({
								type:'success',
								message:"Address updated Successfully"
							})
							setVendorAddress(false);
							props.init()
						} else {
							showToast({
								type:'error',
								message:e.data.message
							})
						}
					})
				}}

				/>:null}
				{
                addLineItem?
                    <Modal buttonName="Add Product" show={addLineItem}
                           title="Search Product"
                           des="Search product to add here"
                           style={
                               {
                                   save: {
                                       width: '100%',
                                       fontSize: 14,
                                       height: 44,
                                       display: 'flex',
                                       justifyContent: 'center',
                                       alignItems: 'center',
                                   }
                               }
                           }
                           onSave={savePoItem}
                           close={() => {
                               setAddLineItem(false)
                           }}>
						  <>
                <ProductSearch
                  product={product}
                  selectCategory={data.category && data.category}
                  onSelect={(item) => {
                    setProduct(item);
                  }}
                />
  				<SearchOffice2
					office={shipping}
					title="Delivery Address"
					onSelect={(add) => {
						setShipping(add);
					}}
				/>
				<DatePickerCustom
					ref={DELIVERY_DATE}
					label={"Delivery Date"}
					formate={dateFormate}
				/>
                <FunctionalInput type="number" title="Quantity" ref={QTTY}  />
                <FunctionalInput type="number" title="Base Amount" ref={BASE}  />
                <FunctionalInput type="text" title="Notes" ref={NOTES} />
                 </>
                    </Modal>:undefined
                  }
				{
                short?
                    <Modal buttonName="Create" show={short}
                           title="Confirmation Dialog"
                           des="Do you want to create purchase order from this items"
                           style={
                               {
                                   save: {
                                       width: '100%',
                                       fontSize: 17,
                                       height: 44,
                                       display: 'flex',
                                       justifyContent: 'center',
                                       alignItems: 'center',
                                   }
                               }
                           }
                           onSave={() => {

                              let remarks = REMARKS.current.value;
                              if(!remarks) {
                                  showToast({
                                      type:'error',
                                      message:"Please enter the remarks"
                                  })
                                  return
                              }
                               post(`vendor/po/requests/${id}/short`, {
                                   remarks
                               }, (e, r) => {
                                   if (r) {

									   showToast({
										   type:'success',
										   message:"Short Closure done successfully"
									   })
                                       REMARKS.current.value ='';
									   props.init();
                                       setShort(false);

                                   } else {
                                       handleError(e)
                                   }
                               })
                           }}
                           close={() => {
                               setShort(false)
                           }}>
                       <FunctionalInput type="text" title="Remarks" ref={REMARKS} />
                    </Modal>:undefined
            }
			{
				name?
				<Modal
					close={()=>setName(false)}
					buttonName="update" show={true}
					title="Confirmation Dialog"
					des={name==='admin'?"Change PO owner":"Change GRN owner"}
					style={
						{
							save: {
								width: '100%',
								fontSize: 17,
								height: 44,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}
						}
					}
					onSave={()=>{


						if(!employee) {
							showToast({
								type:'error',
								message:'Please select the employee'
							})
							return;
						}

					

						if(name==='admin') {
							post(`vendor/po/requests/${id}/po.owner`, {code:employee.value}, (e, r) => {
								if (r) {

									props.init();
									setName(false);
									showToast({
										type: 'success',
										message: "PO owner update successfuly"
									})
									
								} else {
									handleError(e);
								}
							})
						} else {
							post(`vendor/po/requests/${id}/grn.owner`, {code:employee.value}, (e, r) => {
								if (r) {

									props.init();
									setName(false);
									showToast({
										type: 'success',
										message: "GRN owner update successfuly"
									})		

					
									
								} else {
									handleError(e);
								}
							})
						}

						
					}}
					>
					<EmployeeSearchable employee={employee} onSelect={employee=>{
						setEmployee(employee)
					}}/>

				</Modal>
				: null
			}
		</div>
	)
}


export default Details
